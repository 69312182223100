
import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "filter-icon-mixins",
  props: {
    theme: {
      type: String as PropType<"black" | "white">,
      default: "white",
    },
  },
  computed: {
    color(): string {
      return this.theme === "black" ? "#dcdcdc" : "#616161";
    },
  },
});
